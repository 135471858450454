import { template as template_110003b3cf4f438bb5624b48c7afe2ca } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_110003b3cf4f438bb5624b48c7afe2ca(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_110003b3cf4f438bb5624b48c7afe2ca(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_110003b3cf4f438bb5624b48c7afe2ca(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
