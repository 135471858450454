import { template as template_26761e8fd0dc4bbf804d3e4920cc2b04 } from "@ember/template-compiler";
const SidebarSectionMessage = template_26761e8fd0dc4bbf804d3e4920cc2b04(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
