import { template as template_b5edc15b5db943e98a9d8572d6bff304 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b5edc15b5db943e98a9d8572d6bff304(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
