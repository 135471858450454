import { template as template_21f451b080464798a57f2312725958e6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_21f451b080464798a57f2312725958e6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
